.table-nowrap {
  white-space: nowrap;
}

/* --------------------------------------custom-dropzone-css-start-------------------------------------- */
.custom-dropzone {
  border: 2px dashed #e6e6e8;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
}

.custom-dropzone p {
  margin-bottom: unset;
}

.custom-dropzone-file-list {
  margin-top: 15px;
}

.custom-dropzone-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.custom-dropzone-remove-button {
  color: #ff4c51 !important;
  background-color: #ffe2e3 !important;
  border: none;
  padding: 4px 8px;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}

.custom-dropzone-remove-button:hover {
  color: #ffe2e3 !important;
  background-color: #ff4c51 !important;
}
/* --------------------------------------custom-dropzone-css-end-------------------------------------- */

/* --------------------------------------filepond-css-start-------------------------------------- */
.file-pond-main-block {
  border-radius: 4px;
  border: 2px dashed #e6e6e8;
}

.filepond--root {
  margin-bottom: unset;
  font-family: unset;
}

.filepond--item {
  height: auto !important;
}

.filepond--file-info .filepond--file-info-main {
  color: #444050;
  white-space: normal;
}

.filepond--file .filepond--file-status {
  margin-right: unset;
}

.filepond--panel-center.filepond--item-panel {
  display: none;
}

.filepond--panel-bottom.filepond--item-panel {
  display: none;
}

.filepond--drop-label {
  cursor: pointer;
}

.filepond--drop-label label {
  cursor: pointer;
}

.filepond--drop-label {
  background-color: #ffffff;
}

.filepond--file-info .filepond--file-info-sub {
  font-size: 0.725rem;
}

.filepond--item {
  width: 250px;
  transition: transform 0.2s;
}

.filepond--file {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filepond--file-action-button {
  font-size: 14px;
  color: #d9534f;
  margin-top: 8px;
}

.filepond--panel-center.filepond--panel-root {
  background-color: #ffffff !important;
}

.filepond--panel-bottom.filepond--panel-root {
  display: none !important;
}

.filepond--file-wrapper {
  border: 0 solid #e6e6e8;
  border-radius: 0.375rem;
  background-color: #ffffff;
  box-shadow: 0 0.1875rem 0.75rem 0 rgba(47, 43, 61, 0.14);
}

.filepond--file-info {
  color: #000000;
}

.filepond--action-remove-item {
  color: #ff4c51 !important;
  background-color: #ffe2e3 !important;
}

.filepond--credits {
  display: none;
}
/* --------------------------------------filepond-css-end-------------------------------------- */

/* --------------------------------------rsuite-daterange-picker-css-start-------------------------------------- */
.rs-picker-daterange {
  padding: unset;
}

.rs-picker-input-group {
  border: unset;
}

.rs-date-range-input {
  line-height: 1.625;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #1532c7 !important;
}

.rs-btn-primary {
  background-color: #1532c7 !important;
}

.rs-picker-toolbar .rs-stack-item .rs-btn-link {
  color: #1532c7 !important;
}

.rs-calendar-table-cell-in-range::before {
  background-color: #e9e7fd !important;
}

.rs-input-group.rs-input-group-inside .rs-input {
  color: #444050;
}

.rs-input-group-focus {
  color: #444050;
}

.rs-calendar-month-view .rs-calendar-header-title-date,
.rs-calendar-month-dropdown-year-active {
  color: #1532c7 !important;
}

.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  background-color: #1532c7 !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #1532c7;
}

.rs-calendar-table-cell-content:hover {
  color: #1532c7 !important;
  background-color: #e9e7fd !important;
}

@media (max-width: 768px) {
  .rs-picker-popup.rs-picker-popup-daterange {
    width: 46vh;
  }

  .rs-picker-daterange-calendar-group {
    display: flex;
    flex-direction: column;
    height: unset;
    min-width: unset;
  }

  .rs-picker-popup.rs-picker-popup-daterange .rs-calendar:first-child {
    border-right: unset;
    border-bottom: 1px solid var(--rs-border-primary);
  }
}
/* --------------------------------------rsuite-daterange-picker-css-------------------------------------- */

/* --------------------------------------rsuite-date-picker-css-start-------------------------------------- */
.rs-picker-date {
  padding: unset;
}

.rs-input {
  line-height: 1.625;
  font-size: 0.9375rem;
}

.rs-calendar-time-view .rs-calendar-header-title-time {
  color: #1532c7 !important;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #1532c7 !important;
}
/* --------------------------------------rsuite-date-picker-css-end-------------------------------------- */

/* --------------------------------------my-card-css-start-------------------------------------- */
.my-card {
  padding: 0;
}

.my-card .my-card-header {
  padding: 0;
  margin: 0;
}

.my-card-header-head {
  margin: 0;
  padding: 5px 5px 5px 10px;
}

.my-card-body {
  padding: 0px 10px 20px 10px;
}

.my-card-icon-button {
  line-height: 1 !important;
}
/* --------------------------------------my-card-css-end-------------------------------------- */

.sales_document_btn {
  position: absolute;
  width: 20px;
  top: -20px;
  right: -5px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  background: #ff4c51;
  color: white;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
}

.sales_document_image_btn {
  position: absolute;
  width: 20px;
  top: -5px;
  right: -5px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  background: #ff000799;
  color: white;
  padding: 2px;
  border-radius: 30%;
  cursor: pointer;
}
.sales_section_card {
  border: 1px solid #e6e6e8;
  border-radius: 5px;
  padding: 0px 4px 0px 4px;
  position: relative;
  margin: 10px 5px;
}

.sales_section_card .row {
  padding-bottom: 15px;
}

.remove_document_file_btn {
  position: absolute;
  width: 20px;
  top: -5px;
  right: 5px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  background: #ff000799;
  color: white;
  padding: 2px;
  border-radius: 30%;
  cursor: pointer;
}

.image-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.hover-image {
  max-width: 100px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: opacity 0.3s ease;
}

.image-wrapper:hover .hover-image {
  opacity: 0.7;
}

.image-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.375rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-wrapper:hover::after {
  opacity: 1;
}

/* --------------------------------------quill-text-editor-css-start-------------------------------------- */
.ql-toolbar {
  border-radius: 0.375rem 0.375rem 0 0;
}

.ql-container {
  border-radius: 0 0 0.375rem 0.375rem;
}

.ql-editor.ql-blank::before {
  font-style: normal;
}
/* --------------------------------------quill-text-editor-css-end-------------------------------------- */

/* --------------------------------------dashboard-chart-css-start-------------------------------------- */
.apexcharts-toolbar {
  display: none !important;
}
/* --------------------------------------dashboard-chart-css-end-------------------------------------- */

/* --------------------------------------mui-autocomplete-css-start-------------------------------------- */
.MuiInputBase-root {
  height: 38px;
}
.MuiAutocomplete-inputRoot {
  font-size: 0.9375rem !important;
  line-height: 1.625 !important;
  color: #444050 !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  border-radius: 0.375rem !important;
  border-color: #d1d0d4 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  font-family: inherit !important;
}
.MuiInputBase-input::placeholder {
  font-size: 0.9375rem !important;
}
.MuiAutocomplete-option {
  color: #444050 !important;
  font-size: 0.9375rem !important;
  line-height: 1.625 !important;
  font-weight: 400 !important;
  font-family: inherit !important;
}
/* --------------------------------------mui-autocomplete-css-end-------------------------------------- */

/* --------------------------------------sorting-css-start-------------------------------------- */
.sorting-icon {
  margin-left: 3px;
  font-size: 0.8rem;
  opacity: 0.5;
}
/* ---------------------------------------sorting-css-end--------------------------------------- */

/* --------------------------------------radio-button-css-start-------------------------------------- */
.custom-radio-form-control {
  padding: 0 !important;
  line-height: 1.3 !important;
}

.custom-radio-form-control .custom-inline-radio {
  margin-bottom: 5px !important;
  margin-left: 0.8rem !important;
  margin-top: 9px !important;
}
/* ---------------------------------------radio-button-css-end--------------------------------------- */

/* --------------------------------------react-select-css-start-------------------------------------- */
#react-select-3-placeholder {
  color: #acaab1 !important;
  font-family: inherit !important;
}
.css-13cymwt-control {
  border: 1px solid #d1d0d4 !important;
  border-radius: 0.375rem !important;
  font-family: inherit !important;
}
.react-select__option label {
  font-size: 0.9375rem;
  font-weight: 500;
  color: #444050;
}
.react-select__option--is-selected,
.react-select__option--is-selected label,
.react-select__option--is-selected span {
  color: white !important;
}
/* ---------------------------------------react-select-css-end--------------------------------------- */
